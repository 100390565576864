import React from 'react';
import styled from 'styled-components';

import { Icon } from '../Icons';
import { BodyStyle2, BodyStyle3 } from '../../../styles/fonts';
import { Colors } from '../../../styles/colors';

export const Pill = ({ fillColor, color, icon, isSmall, isBold, onClick, children }: PillProps) => {
  const fill = fillColor || (color ? `${color}11` : undefined);
  return (
    <PillContainer
      $fillColor={fill}
      color={color}
      $isSmall={isSmall}
      $isBold={isBold}
      onClick={onClick}
      $isClickable={Boolean(onClick)}
    >
      {icon}
      {children}
    </PillContainer>
  );
};

export const PillsList = styled.div`
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
`;

const PillContainer = styled.div<{
  color?: string;
  $fillColor?: string;
  $isSmall?: boolean;
  $isBold?: boolean;
  $isClickable?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  border: 1px solid red;

  ${({ $isSmall }) => ($isSmall ? BodyStyle3 : BodyStyle2)}

  ${({ $fillColor, $isSmall, $isBold }) =>
    `
      gap: ${$isSmall ? '2px' : '4px'};
      font-weight: ${$isBold ? 'bold' : 'normal'};
      padding: ${$isSmall ? '2px 5px' : '7px 18px'} ;
      background-color: ${$fillColor || 'transparent'};
    `}

  ${({ color }) =>
    color &&
    `
      color: ${color};
      border: 1px solid ${Colors.GreyLight};
    `}

  width: fit-content;

  ${({ $isClickable }) =>
    $isClickable &&
    `
      cursor: pointer;
    `}

  ${Icon} {
    margin-left: 2px;
    flex-shrink: 0;
    transform: translateY(-1px);
  }
`;

type PillProps = {
  fillColor?: string;
  color?: string;
  icon?: React.ReactElement;
  small?: boolean;
  isBold?: boolean;
  isSmall?: boolean;
  children: any;
  onClick?: any;
};
